<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="cash_reference_id"
                        :name="beForm[rep].cash_reference_id.label"
                        :label="beForm[rep].cash_reference_id.label"
                        v-model="form[rep].cash_reference_id"
                        :options="cash_references_opt"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        @input="onCashReferenceInput"
                        :rules="getRules('cash_reference_id')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="cash_entry_type"
                        label="Tipo Movimento"
                        v-model="form[rep].cash_entry_type"
                        maxlength="256"
                        :disabled="true"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="treasury_id"
                        :name="beForm[rep].treasury_id.label"
                        :label="beForm[rep].treasury_id.label"
                        v-model="form[rep].treasury_id"
                        :options="beForm[rep].treasury_id.options"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="getRules('treasury_id')"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="cash_time"
                        :name="beForm[rep].cash_time.label"
                        :label="beForm[rep].cash_time.label"
                        v-model="form[rep].cash_time"
                        :rules="getRules('cash_time')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="broker_id"
                        :name="beForm[rep].broker_id.label"
                        label="Produttore"
                        v-model="form[rep].broker_id"
                        :options="getSalesmen()"
                        :taggable="false"
                        :multiple="false"
                        :closeOnSelect="true"
                        :rules="getRules('broker_id')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        vid="title"
                        :name="beForm[rep].title.label"
                        label="Descrizione"
                        v-model="form[rep].title"
                        placeholder="Inserisci una descrizione"
                        :rules="{ required: true }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        :name="beForm[rep].gross.label"
                        vid="gross"
                        label="Importo"
                        v-model="form[rep].gross"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                        :rules="getRules('gross')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>

              <div class="mt-2" v-show="customInputs[rep].length">
                <b-card header="Attributi personalizzati" header-tag="header">
                  <b-card-text>
                    <custom-inputs
                      :customInputs="customInputs[rep]"
                      v-model="form[rep]"
                      :beForm="beForm[rep]"
                      :beRules="beRules[rep]"
                    />
                  </b-card-text>
                </b-card>
              </div>

              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                  >Reset</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="outline-lisaweb"
                  size="sm"
                  class="btn-reset float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>

      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import CustomInputs from "@/components/form/CustomInputs";
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCurrency from "@/components/form/BaseCurrency";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import { mapGetters } from "vuex";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
import { toLocaleDate } from "@/utils/dates";

export default {
  mixins: [FormMixin, ShortcutMixin, ConfirmModalMixin],
  data() {
    return {
      repository: "cash_entry",
      id: this.$route.params.id,
      item: this.$route.params.id,
      cash_references_opt: [],
      DEPOSITED_CASH_REFERENCE: 1,
      has_broker: {},
      gross_rule: { required: true },
      form: {
        cash_entry: {
          broker_id: null,
          cash_entry_id: null,
          cash_reference_id: null,
          cash_time: null,
          gross: 0.0,
          title: null,
          treasury_id: null,
        },
      },
    };
  },
  components: {
    CustomInputs,
    BaseIcon,
    BaseInput,
    BaseSelect,
    BaseCurrency,
    BaseDatepicker,
  },
  methods: {
    ...mapGetters("auth", {
      getCashReferences: "cash_references",
      getTreasuries: "allTreasuries",
      getSalesmen: "salesmen",
    }),
    onSubmit() {
      this.form[this.rep].gross = Math.abs(this.form[this.rep].gross);
      this.form[this.rep].cash_time = toLocaleDate(
        this.form[this.rep].cash_time,
        "YYYY-MM-DD HH:mm:ss"
      );

      if (this.hasReferencedCashReference) {
        this.showConfirm({
          yesCallback: () => {
            this.onSuccess();
          },
          noCallback: null,
          title: "Conferma Modifica",
          message:
            "Stai per modificare un movimento associato ad una causale con causale automatica valorizzata. La variazione non modificherà nulla nella registrazione contabile collegata. Vuoi Procedere?",
          yesLabel: "CONFERMA",
          noLabel: "ANNULLA",
        });
      } else {
        this.onSuccess();
      }
    },
    onSuccess() {
      this.isLoading = true;
      this.update(this.repository, this.id)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Movimento Contabile Modificato`,
          });
          this.shortcut(
            "cash_entries.index0",
            null,
            "#Deposited",
            "filterCashEntriesDeposited"
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    hasReferencedCashReference() {
      this.item?.cash_reference?.cash_reference_id ? true : false;
    },
    onCashReferenceInput(value) {
      if (value) {
        this.setCashReferenceRules(value);
      } else {
        this.gross_rule = { required: true };
      }
    },
    setCashReferenceRules(value) {
      const found = this.cash_references_opt.find(
        (cash_reference) => cash_reference.value === value
      );
      if (found) {
        this.form.cash_entry.treasury_id = found.treasury_id;
        this.form.cash_entry.cash_entry_type = found.entry_type.text;
        this.has_broker =
          found.has_broker.value === "Y" ? { required: true } : {};

        const ENTRY_IN_RULE = {
          required: true,
          compare_number: { otherValue: "0", operator: ">" },
        };
        const ENTRY_OUT_RULE = {
          required: true,
          compare_number: { otherValue: "0", operator: "<" },
        };
        this.gross_rule =
          found.entry_type.value === 0 ? ENTRY_IN_RULE : ENTRY_OUT_RULE;
      }
    },
  },
  created() {
    this.isLoading = true;
    this.fetchEditForm(this.repository, this.id).then(() => {
      this.cash_references_opt = [];
      let cashReferences = this.getCashReferences();
      cashReferences.sort((a, b) => a.text - b.text);
      cashReferences.forEach((el) => {
        this.cash_references_opt.push({
          text: `${el.text} - ${el.description}`,
          reference_type: el.reference_type,
          has_broker: el.has_broker,
          entry_type: el.entry_type,
          value: el.value,
          treasury_id: el.treasury_id,
        });
      });
      this.cash_references_opt = this.cash_references_opt.filter(
        (cash_reference) =>
          cash_reference.reference_type.value == this.DEPOSITED_CASH_REFERENCE
      );
      this.setCashReferenceRules(this.form[this.rep].cash_reference_id);
      this.isLoading = false;
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
