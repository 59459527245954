<template>
  <div class="mt-2">
    <!-- <div :style="[!noInnerWidth ? { width: width } : {}]"> -->
    <div :style="{ width: !noInnerWidth ? width : 'auto' }">
      <div>
        <b-table
          :busy="isLoading"
          head-variant="light"
          :no-local-sorting="true"
          :items="items"
          :fields="getAllFields"
          :current-page="currentPage"
          per-page="0"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :sticky-header="height"
          no-border-collapse
          responsive
          show-empty
          empty-text="Non ci sono informazioni da mostrare"
          small
          @sort-changed="sortingChanged"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <base-icon name="loading" width="35" height="35" />
              <p>Operazione in corso...</p>
            </div>
          </template>
          <template #cell(cash_reference_full_code)="{ item }">
            {{ item.causale.codice }} - {{ item.causale.descrizione }}
          </template>
          <template #cell(entry_in)="{ item }">
            {{
              item.causale.tipo_di_movimento == 0
                ? toLocaleCurrency(Math.abs(item.importo))
                : toLocaleCurrency(0)
            }}
          </template>
          <template #cell(entry_out)="{ item }">
            {{
              item.causale.tipo_di_movimento == 1
                ? toLocaleCurrency(Math.abs(item.importo))
                : toLocaleCurrency(0)
            }}
          </template>
          <template #cell(actions)="row">
            <div class="action-buttons">
              <b-button
                v-if="actions.includes('infomodal')"
                size="sm"
                variant="lisaweb"
                @click="openModal(row.item, row.index, $event.target)"
                class="mt-1 mr-1"
                title="Modale Dettagli"
              >
                <b-icon icon="eye"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('details')"
                size="sm"
                variant="lisaweb"
                @click="row.toggleDetails"
                class="mt-1 mr-1"
                :title="
                  row.detailsShowing ? 'Chiudi dettagli' : 'Apri dettagli'
                "
              >
                <b-icon v-if="row.detailsShowing" icon="chevron-up"></b-icon>
                <b-icon v-else icon="chevron-down"></b-icon>
              </b-button>

              <b-button
                v-if="actions.includes('edit')"
                size="sm"
                variant="lisaweb"
                :disabled="!isStandard(row.item)"
                @click="onEdit(row.item.id, row.item)"
                class="mt-1 mr-1"
                title="Modifica"
              >
                <b-icon icon="pencil-square"></b-icon>
              </b-button>
              <b-button
                v-if="actions.includes('destroy')"
                :disabled="!isStandard(row.item)"
                size="sm"
                variant="lisaweb"
                @click="onDestroy(row.item.id, row.item)"
                class="mt-1 mr-1"
                title="Elimina"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </div>
          </template>
          <template
            slot="thead-top"
            slot-scope="data"
            v-if="Object.keys(previous_balance).length && items.length"
          >
            <td v-for="(field, i) in data.fields" :key="i" class="totalRow">
              <span
                v-if="i !== 0 && field.key === 'title'"
                v-html="`Saldo al ${toLocaleDate(previous_balance_date)}`"
              >
              </span>
              <span
                v-if="i !== 0 && field.key === 'entry_in'"
                class="info"
                v-html="`${previous_balance.balance &gt; 0 ? toLocaleCurrency(previous_balance.balance) : toLocaleCurrency(0)}`"
              >
              </span>
              <span
                v-if="i !== 0 && field.key === 'entry_out'"
                class="info"
                v-html="`${previous_balance.balance &lt; 0 ? toLocaleCurrency(Math.abs(previous_balance.balance)) : toLocaleCurrency(0)}`"
              >
              </span>
            </td>
          </template>
          <template slot="bottom-row" v-if="type">
            <td colspan="7">
              <div class="row pagination--intable">
                <div class="show-text">
                  <p>Mostra</p>
                </div>

                <div class="group">
                  <b-form-group
                    label-for="per-page-select"
                    label-cols-sm="3"
                    label-cols-md=""
                    label-cols-lg="3"
                    label-size="sm"
                    md="auto"
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      style="width: 68px"
                      size="sm"
                      @change="onPageChange(1)"
                      :disabled="!items.length"
                    ></b-form-select>
                  </b-form-group>
                </div>

                <div class="page">
                  <p>di {{ totalItems }} per pagina</p>
                </div>
                <div class="pagination" v-if="totalItems">
                  <b-pagination
                    v-model="currentPage"
                    limit="3"
                    :total-rows="totalItems"
                    :per-page="perPage"
                    size="sm"
                    @change="onPageChange"
                  ></b-pagination>
                </div>
              </div>
            </td>
          </template>
          <template
            slot="custom-foot"
            slot-scope="data"
            v-if="Object.keys(current_balance).length && items.length"
          >
            <td
              v-for="(field, i) in data.fields"
              :key="i"
              class="totalRow totalRow--upper-pagination"
            >
              <span
                v-if="i !== 0 && field.key === 'title'"
                v-html="
                  `TOTALE REGISTRAZIONI <br><br> Saldo al ${toLocaleDate(
                    current_balance_date
                  )}`
                "
              >
              </span>
              <span
                v-if="i !== 0 && field.key === 'entry_in'"
                class="info"
                v-html="`${toLocaleCurrency(current_balance.entry_in)} <br><br> ${totalBalance() &gt; 0 ? toLocaleCurrency(totalBalance()) : toLocaleCurrency(0)}`"
              >
              </span>
              <span
                v-if="i !== 0 && field.key === 'entry_out'"
                class="info"
                v-html="`${toLocaleCurrency(current_balance.entry_out)} <br><br> ${totalBalance() &lt; 0 ? toLocaleCurrency(totalBalance()) : toLocaleCurrency(0)}`"
              >
              </span>
            </td>
          </template>
        </b-table>
      </div>
      <b-row v-if="!type">
        <div class="show-text">
          <p>Mostra</p>
        </div>

        <div class="group">
          <b-form-group
            label-for="per-page-select"
            label-cols-sm="3"
            label-cols-md=""
            label-cols-lg="3"
            label-size="sm"
            md="auto"
          >
            <b-form-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
              style="width: 68px"
              size="sm"
              @change="onPageChange(1)"
              :disabled="!items.length"
            ></b-form-select>
          </b-form-group>
        </div>

        <div class="page">
          <p>di {{ totalItems }} per pagina</p>
        </div>
        <div class="pagination" v-if="totalItems">
          <b-pagination
            v-model="currentPage"
            limit="3"
            :total-rows="totalItems"
            :per-page="perPage"
            size="sm"
            @change="onPageChange"
          ></b-pagination>
        </div>
      </b-row>
      <b-modal
        :id="infomodalName"
        @hidden="onModalHidden"
        :title="modalTitle"
        size="xl"
        scrollable
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <div>
          <b-tabs content-class="pt-1" lazy v-model="tabIndex">
            <b-tab
              v-for="(tab, index) in tabs"
              :key="index"
              @click="setTab(tab, index)"
              :title="tab.text"
              :active="index === tabIndex"
              title-item-class="lisaweb"
            >
              <keep-alive>
                <component
                  v-if="selected === tab.name"
                  :is="selected"
                  :resourceId="modalResourceId"
                  @fetch="fetch"
                ></component>
              </keep-alive>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import template from "./template";
import General from "@/components/profile/companies-products/insurers/details/General";
import BaseIcon from "@/components/BaseIcon";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import moment from "moment";
moment.locale("it");

export default {
  name: "CashEntriesTable",
  extends: template,
  data() {
    return {
      tabs: [{ name: "General", text: "Generale" }],
      selected: "General",
      modalResourceId: null,
      tabIndex: 0,
    };
  },
  components: {
    General,
    BaseIcon,
  },
  methods: {
    toLocaleCurrency,
    toLocaleDate,
    isStandard(item) {
      return item?.causale?.tipologia === 0;
    },
    totalBalance() {
      return (
        this.current_balance.entry_in +
        this.current_balance.entry_out +
        this.previous_balance.entry_in +
        this.previous_balance.entry_out
      );
    },
  },
  props: {
    type: {
      type: String,
      default() {
        return "";
      },
    },
    previous_balance_date: {
      type: String,
      default() {
        return "";
      },
    },
    current_balance_date: {
      type: String,
      default() {
        return "";
      },
    },
    previous_balance: {
      type: Object,
      default() {
        return {};
      },
    },
    current_balance: {
      type: Object,
      default() {
        return {};
      },
    },
    cash_reference_full_code: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>

<style lang="css" scoped>
.totalRow {
  text-align: right;
}
.totalRow {
  text-align: right;
}
.info {
  text-align: right;
}
.b-table-bottom-row {
  td {
    background-color: #fafafa;
    padding-block: 8px;
  }
}
tfoot,
.totalRow--upper-pagination {
  background-color: #e1ddda;
}
.table thead th,
thead,
th {
  background: 0 !important;
}
</style>
