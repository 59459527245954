<template>
  <div>
    <div class="mx-1" v-if="canVerb(resource, 'index')">
      <b-row>
        <b-col align="left">
          <b-button-group
            v-b-toggle:collapse-1
            class="my-2 filter-button-group"
            :title="getDefaultFilterMessage()"
          >
            <span class="when-open">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            <span class="when-closed">
              <b-icon icon="funnel" font-scale="1.5"></b-icon>
            </span>
            Filtra
          </b-button-group>
        </b-col>
      </b-row>

      <b-collapse visible id="collapse-1">
        <b-form @submit.prevent="onSearch()">
          <b-card class="filter">
            <b-row>
              <div class="col-md-3">
                <base-select
                  vid="conto"
                  name="conto"
                  label="Conto"
                  v-model="filter.conto"
                  :options="treasuries_opt"
                  :taggable="false"
                  :multiple="false"
                  :closeOnSelect="true"
                  @input="onInputTreasury()"
                />
              </div>
              <div class="col-md-3">
                <base-datepicker
                  name="cash_time"
                  label="Saldo conto al"
                  v-model="filter.fino_al"
                />
              </div>
              <div class="form-group col-md-3 align-self-end">
                <b-button type="submit" variant="lisaweb" size="sm"
                  >Calcola</b-button
                >
                <b-button
                  class="btn-reset"
                  type="button"
                  variant="lisaweb"
                  size="sm"
                  @click="onClearFilter()"
                  >Reset</b-button
                >
              </div>
            </b-row>
          </b-card>
          <b-overlay center :show="isLoading" rounded="sm">
            <b-card class="filter mt-2" v-if="filter.conto && search">
              <div class="col-md-12">
                <div :class="status">
                  <h1 class="total">
                    Il saldo del conto
                    {{ treasury }}
                    è :
                    {{ toLocaleCurrency(data) }}
                  </h1>
                </div>
              </div>
            </b-card>
            <template #overlay>
              <div :class="`text-center${search ? ' mt-3' : ' mt-9'}`">
                <base-icon name="loading" width="35" height="35" />
                <p id="cancel-label">Operazione in corso...</p>
              </div>
            </template>
          </b-overlay>
        </b-form>
      </b-collapse>
    </div>
    <div v-else class="mt-3">
      <b-icon icon="info-circle" scale="1.00"></b-icon>
      Non hai i permessi
    </div>
  </div>
</template>

<script>
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import { mapGetters } from "vuex";
import BaseIcon from "@/components/BaseIcon";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { toLocaleCurrency } from "@/utils/strings";
import { today } from "@/utils/dates";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      repository: "cash_entry",
      resource: "cash_entries",
      treasuries_opt: [],
      data: 0,
      isLoading: false,
      search: false,
      status: "default",
      filter: this.initFilter(),
      treasury: null,
    };
  },
  components: {
    BaseDatepicker,
    BaseIcon,
    BaseSelect,
  },
  methods: {
    toLocaleCurrency,
    initFilter() {
      let init = {
        byAttribute: {
          cash_time: null,
        },
        fino_al: today(),
        conto: null,
      };
      return init;
    },
    onSearch() {
      if (this.filter.fino_al && this.filter.conto) {
        this.isLoading = true;
        const Repo = RepositoryFactory.get(this.repository);
        const queryString = `fino_al=${this.filter.fino_al}&conto=${this.filter.conto}`;
        Repo.summary(queryString)
          .then((response) => {
            this.data = response?.data?.saldo ? response.data.saldo : 0;
            this.setTotalizerStatus();
            this.$showSnackbar({
              preset: "success",
              text: `Calcolo 'Saldo Conto' terminato`,
            });
            this.search = true;
            this.isLoading = false;
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
            this.isLoading = false;
          });
      } else {
        this.$showSnackbar({
          preset: "success",
          text: `Devi impostare i campi 'data Al' e 'saldo del conto'`,
        });
      }
    },
    onInputTreasury() {
      this.treasury = !this.filter.conto
        ? null
        : this.treasuries_opt.find(
            (treasury) => treasury.value == this.filter.conto
          )?.text;
      this.search = false;
    },
    setTotalizerStatus() {
      if (this.data > 0) {
        this.status = "positive";
      } else if (this.data < 0) {
        this.status = "negative";
      } else {
        this.status = "default";
      }
    },
    onClearFilter() {
      this.filter.fino_al = null;
      this.filter.conto = null;
    },
    ...mapGetters("auth", {
      getContiPrimaNota: "allContiPrimaNota",
    }),
  },
  created() {
    this.treasuries_opt = this.getContiPrimaNota().map((el) => {
      return {
        value: el.value,
        text: el.text,
      };
    });
  },
};
</script>

<style scoped>
.total {
  font: 15px;
  color: black;
}
.positive {
  background-color: rgb(33, 206, 33);
}
.negative {
  background-color: red;
}
</style>
