<template>
  <div class="mt-1 mx-3">
    <div>
      <b-tabs content-class="pt-1" lazy v-model="tabIndex">
        <b-tab
          v-for="(tab, index) in tabs"
          :key="index"
          @click="onTabClick(tab, index)"
          :title="tab.text"
          :active="active(index)"
          title-item-class="lisaweb"
        >
          <keep-alive>
            <component :is="selected"></component>
          </keep-alive>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import BankStatement from "@/components/accounting/cash-entries/bank-statement/index.vue";
import BookEntries from "@/components/accounting/cash-entries/book-entries/index.vue";
import Deferred from "@/components/accounting/cash-entries/deferred/index.vue";
import Deposit from "@/components/accounting/cash-entries/deposit/index.vue";
import ExportCashEntries from "@/components/accounting/cash-entries/exp-cash-entry/index.vue";

import ShortcutsAndBreadcrumbsMixin from "@/mixins/ShortcutsAndBreadcrumbsMixin";

export default {
  mixins: [ShortcutsAndBreadcrumbsMixin],
  data() {
    return {
      path: "cash_entries.index0",
      tabs: [
        { name: "Deferred", text: "Sospesi" },
        { name: "Deposit", text: "Acconti" },
        { name: "BookEntries", text: "Altri Movimenti" },
        { name: "BankStatement", text: "Saldo Conto" },
        { name: "ExportCashEntries", text: "Estratto Conto" },
      ],
      tabsGroup: "Management",
      // breadcrumbs settings: bcs & maxLevel
      bcs: [
        {
          text: "Contabilità",
          path: "",
          level: 0,
          disabled: true,
        },
        {
          text: "Contabilità",
          path: "",
          level: 1,
        },
        {
          text: "Contabilità Giornaliera",
          path: "",
          level: 2,
        },
        {
          text: "Movimenti Contabili",
          path: "cash_entries.index0",
          tab: `#${this.selected}`,
          level: 3,
        },
      ],
      maxLevel: 3, // use to identify the tabs level
      selected: "Deferred",
      tabIndex: 0,
      // isLoading: false,
    };
  },
  components: {
    BankStatement,
    BookEntries,
    Deferred,
    Deposit,
    ExportCashEntries,
  },
};
</script>
