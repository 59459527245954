<template>
  <div class="mt-1 mx-3">
    <create-cash-entries-deferred> </create-cash-entries-deferred>
  </div>
</template>

<script>
import CreateCashEntriesDeferred from "@/components/accounting/cash-entries/deferred/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    CreateCashEntriesDeferred,
  },
  created() {
    this.notifyQueue({
      text: "Crea Sospeso",
      path: "cash_entries.create1",
      level: 4,
    });
  },
};
</script>
