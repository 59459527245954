<template>
  <div class="mx-1" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          :id="`popover-create`"
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAdd"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
        <b-button
          size="sm"
          variant="primary"
          title="Chiusura Massiva"
          :disabled="!this.checkedRows || !this.isDeferred"
          @click="openMassReducedModal"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Chiusura Massiva
        </b-button>
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-select
                name="salesmen"
                label="Produttore"
                :options="getSalesmen()"
                v-model="filter.produttore"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="deferred"
                label="Tipologia"
                :options="deferred"
                v-model="filter.stato"
                @select="onSelectDeferredType"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3">
              <base-datepicker
                name="cash_time"
                label="Data Apertura dal"
                v-model="filter.data_registrazione_inizio"
                :readonly="lockedDates"
                :disabled="lockedDates"
              />
            </div>
            <div class="form-group col-md-3">
              <base-datepicker
                name="cash_time"
                label="Data Apertura al"
                v-model="filter.data_registrazione_fine"
                :readonly="lockedDates"
                :disabled="lockedDates"
              />
            </div>
            <div class="form-group col-md-3">
              <base-datepicker
                name="cash_history"
                label="Data Recupero dal"
                v-model="filter.data_recupero_inizio"
                :readonly="lockedDates"
                :disabled="lockedDates"
              />
            </div>
            <div class="form-group col-md-3">
              <base-datepicker
                name="cash_history"
                label="Data Recupero al"
                v-model="filter.data_recupero_fine"
                :readonly="lockedDates"
                :disabled="lockedDates"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>
    <deferred-cash-entries-table
      @edit="onEdit"
      @interaction="onInteraction"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      hasChecks
      :filterName="filterName"
      :filterOn="{
        tipo: 'sospeso',
        byRelations: [
          'byBroker',
          'byTreasury',
          'byCashReference',
          'byCashEntryHistory',
        ],
      }"
      @destroy="onDestroy"
      :ref="tableRef"
      :onlyActions="['edit', 'destroy', 'infomodal']"
    ></deferred-cash-entries-table>
    <mass-reduced-modal
      :grandtotaldeferred="grandtotaldeferred"
      :minMassReducedDate="minMassReducedDate"
      @save="onSaveGrandTotalDeferred"
    ></mass-reduced-modal>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import DeferredCashEntriesTable from "@/components/tables/DeferredCashEntry.vue";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseSelect from "@/components/form/BaseSelect";
import { mapActions, mapGetters } from "vuex";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import massReducedModal from "@/components/modals/cashEntryMassReducedModal";
import moment from "moment";

export default {
  mixins: [CustomFiltersMixin],
  data() {
    return {
      repository: "cash_entry",
      resource: "cash_entries",
      tableRef: "CashEntriesDeferredTableRef",
      filterName: "filterCashEntriesDeferred",
      grandtotaldeferred: 0,
      customFilter: {
        customByDeferred: "yes",
        customByBroker: null,
      },
      minMassReducedDate: "1970-01-01",
      activeCheckboxes: [],
      checkedRows: false,
      countItems: false,
      isDeferred: true,
      filter: this.initFilter(),
      lockedDates: false,
      deferred: [
        { value: "tutti", text: "Tutti" },
        { value: "aperti", text: "Aperti" },
        { value: "aperti-parziali", text: "Aperti e parzialmente recuperati" },
        { value: "chiusi", text: "Chiusi" },
        { value: "chiusi-parziali", text: "Chiusi e parzialmente incassati" },
      ],
      fields: [
        {
          key: "check",
          label: "",
          sortable: false,
          thClass: "check",
          class: "check",
        },
        {
          key: "conto.codice",
          label: this.getDictionary("Conto"),
          sortable: true,
          sortKey: "byAttribute.conto",
        },
        {
          key: "data_registrazione",
          formatter: (value) => toLocaleDate(value),
          label: this.getDictionary("cash_time", "cash_entry"),
          sortable: true,
          sortKey: "byAttribute.data_registrazione",
        },
        {
          key: "recuperi",
          formatter: (value) => this.getLastCashEntry(value),
          label: "Data ultimo recupero",
          sortable: true,
          sortKey: "byAttribute.data_recupero",
        },
        {
          key: "descrizione",
          label: this.getDictionary("description", "cash_entry"),
          sortable: true,
          sortKey: "byAttribute.descrizione",
        },
        {
          key: "causale",
          label: "Causale",
          formatter: (value) => `${value.codice} - ${value.descrizione}`,
          sortable: true,
          sortKey: "byAttribute.causale",
        },
        {
          key: "produttore.formatted_title",
          formatter: (value) => (value ? value : "-"),
          label: this.getDictionary("broker_id", "cash_entry"),
          sortable: true,
          sortKey: "byAttribute.produttore",
        },
        {
          key: "importo",
          formatter: (value) => toLocaleCurrency(Math.abs(parseFloat(value))),
          label: this.getDictionary("Importo"),
          tdClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          sortable: true,
          sortKey: "byAttribute.importo",
        },
        {
          key: "recupero",
          formatter: (value) => toLocaleCurrency(Math.abs(parseFloat(value))),
          label: "Incassato",
          tdClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
          sortable: true,
          sortKey: "byAttribute.incassato",
        },
        {
          key: "residuo",
          formatter: (value, field, item) =>
            toLocaleCurrency(
              item.importo - (item.recupero ? item.recupero : 0)
            ),
          label: this.getDictionary("Residuo"),
          tdClass: "text-right",
          thStyle: {
            "text-align": "right",
          },
        },
      ],
    };
  },
  components: {
    DeferredCashEntriesTable,
    BaseSelect,
    BaseDatepicker,
    massReducedModal,
  },
  methods: {
    onSelectDeferredType(selected) {
      switch (selected) {
        case "tutti":
          this.lockedDates = true;
          break;
        default:
          this.lockedDates = false;
          break;
      }
      this.visible = true;
    },
    getLastCashEntry(value) {
      if (!value || !value.length) return "-";
      const lastCashEntry = value.at(-1);
      if (!lastCashEntry || !lastCashEntry.data_registrazione) return "-";
      return toLocaleDate(lastCashEntry.data_registrazione);
    },
    onInteraction(selectedRows, reducedDate, checked) {
      if (checked) {
        this.activeCheckboxes.push(reducedDate);
      } else {
        this.activeCheckboxes = this.activeCheckboxes.filter(
          (v) => v !== reducedDate
        );
      }
      const arrSet = [...new Set(this.activeCheckboxes)];
      this.minMassReducedDate = arrSet.length
        ? arrSet.reduce((a, b) => (moment(a).isAfter(b) ? a : b))
        : "1970-01-01";
      this.checkedRows = selectedRows && selectedRows > 0 ? true : false;
    },
    initFilter() {
      let init = {
        stato: "aperti",
        byAttribute: {
          title: null,
          cash_time: null,
          cash_history: null,
        },
        byCashReference: {
          reference_type: 2,
        },
        byBroker: {
          id: null,
        },
        byDeferred: "yes",
        byCashTime: {
          to: null,
          from: null,
        },
        byCashHistory: {
          to: null,
          from: null,
        },
      };
      return init;
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onEdit(id) {
      this.$router.push({
        name: `${this.resource}.edit1`,
        params: { id: `${id}` },
      });
    },
    onAdd() {
      this.$router.push({ name: `${this.resource}.create1` });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.cancellaSospeso(id)
        .then(() => {
          this.$refs[this.tableRef].sortBy = "data_registrazione";
          this.$refs[this.tableRef].sortAsc = true;
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Sospeso Eliminato con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    openMassReducedModal() {
      this.grandtotaldeferred =
        this.$refs["CashEntriesDeferredTableRef"].grandTotalDeferred;
      this.$bvModal.show("massReducedModal");
    },
    onSearch(name) {
      if (this.filter.byDeferred) {
        let criteria = this.filter;
        this.saveFilterByName({ name, criteria });
        this.removePaginationByName(name);
        this.$refs[this.tableRef].currentPage = 1;
        this.isDeferred = false;
        this.$refs[this.tableRef].sortBy = "data_registrazione";
        this.$refs[this.tableRef].sortAsc = true;
        this.$refs[this.tableRef].fetch().then(() => {
          this.isDeferred = !this.$refs[this.tableRef].items.length
            ? false
            : this.$refs[this.tableRef].items.every(
                (item) => item.gross + item.reduced != 0
              );
          this.onInteraction;
        });
      } else {
        this.$showSnackbar({
          preset: "success",
          text: "Il campo 'Tipologia' è obbligatorio",
        });
      }
    },
    onSaveGrandTotalDeferred(form) {
      const items = this.$refs[this.tableRef]?.items;
      if (items.length) {
        const checkedItems = items.filter((item) => item.check);
        const deferrableItems = checkedItems.filter(
          (item) => item.gross - item.reduce != 0
        );
        if (deferrableItems.length) {
          const payload = {};
          payload.ids = [];
          deferrableItems.forEach((item) => {
            payload.ids.push(item.id);
          });
          payload.id_conto_prima_nota = form.treasury_id;
          payload.data_registrazione = toLocaleDate(
            form.cash_time,
            "YYYY-MM-DD"
          );
          payload.id_causale_prima_nota = form.cash_reference_id;
          const Repo = RepositoryFactory.get(this.repository);
          Repo.bulk_update(payload)
            .then(() => {
              this.$showSnackbar({
                preset: "success",
                text: "Chiusura Massiva Completata",
              });
              this.$refs[this.tableRef].sortBy = "data_registrazione";
              this.$refs[this.tableRef].sortAsc = true;
              this.$refs[this.tableRef].fetch();
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
            });
        } else {
          this.$showSnackbar({
            preset: "success",
            text: "Non ci sono elementi selezionati",
          });
        }
      } else {
        this.$showSnackbar({
          preset: "success",
          text: "Non ci sono sospesi da chiudere",
        });
      }
    },
    onClearFilter(name) {
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
    }),
    ...mapGetters("filters", {
      loadByName: "loadByName",
    }),
  },
};
</script>
