<template>
  <div class="mt-1 mx-3">
    <create-cash-entries-default> </create-cash-entries-default>
  </div>
</template>

<script>
import CreateCashEntriesDefault from "@/components/accounting/cash-entries/book-entries/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    CreateCashEntriesDefault,
  },
  created() {
    this.notifyQueue({
      text: "Crea Movimento Contabile",
      path: "cash_entries.create0",
      level: 4,
    });
  },
};
</script>
