<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <details-card
          v-for="(item, index) in detailFields"
          :key="index"
          :header="item.header"
          :edit="item.edit || false"
          :fields="item.fields"
          :beForm="beForm"
          :data="details"
          :repository="item.repository"
        >
        </details-card>
        <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
          <b-card header="true" header-tag="header" class="mt-2">
            <div
              slot="header"
              class="d-flex justify-content-between align-items-center"
            >
              <p class="mb-0 d-inline-block align-middle">
                <b>Recupero Sospeso</b>
              </p>
            </div>
            <div v-if="details.importo > details.recupero">
              <b-row>
                <div class="col-md-4">
                  <base-select
                    vid="cash_reference_id"
                    name="causale"
                    label="Causale"
                    v-model="form.cash_reference_id"
                    :options="cash_references_opt"
                    :taggable="false"
                    :multiple="false"
                    :closeOnSelect="true"
                    @input="onCashReferenceInput"
                    :rules="{ required: true }"
                    :preselectFirst="true"
                  />
                </div>
                <div class="col-md-4">
                  <base-datepicker
                    vid="valuta_da"
                    name="Data Recupero Sospeso"
                    label="Data Recupero Sospeso"
                    v-model="form.cash_time"
                    :min="minDateSospeso"
                    @select="onDetailDateSelect"
                    :rules="{ required: true }"
                  />
                </div>
                <div class="col-md-4">
                  <base-select
                    vid="broker_id"
                    name="produttore"
                    label="Produttore"
                    v-model="form.broker_id"
                    :options="getSalesmen()"
                    :taggable="false"
                    :multiple="false"
                    :closeOnSelect="true"
                    :rules="has_broker"
                    :disabled="true"
                  />
                </div>
                <div class="col-md-4">
                  <base-currency
                    name="importo"
                    vid="gross"
                    label="Importo"
                    v-model="form.gross"
                    :options="{
                      currency: 'EUR',
                      locale: 'it-IT',
                      precision: 2,
                    }"
                    :rules="{
                      required: true,
                      compare_number: { otherValue: '0', operator: '>' },
                    }"
                  />
                </div>
                <div class="col-md-4">
                  <base-select
                    vid="treasury_id"
                    name="conto"
                    label="Conto"
                    v-model="form.treasury_id"
                    :options="getContiPrimaNota()"
                    :taggable="false"
                    :multiple="false"
                    :closeOnSelect="true"
                    :rules="{ required: true }"
                  />
                </div>
                <div class="col-md-4">
                  <input type="hidden" name="title" :value="form.title" />
                </div>
              </b-row>

              <b-button
                type="button"
                variant="lisaweb"
                class="mb-2"
                @click="handleSubmit(onSubmit)"
                :disabled="invalid"
                >Recupera</b-button
              >
            </div>
            <div v-else>
              <b>Sospeso Chiuso</b>
            </div>
          </b-card>
        </validation-observer>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import DetailsCard from "@/components/DetailsCard";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { toLocaleCurrency } from "@/utils/strings";
import BaseIcon from "@/components/BaseIcon";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseSelect from "@/components/form/BaseSelect";
import BaseCurrency from "@/components/form/BaseCurrency";
import { toLocaleDate } from "@/utils/dates";
import moment from "moment";
moment.locale("it");
import { mapGetters } from "vuex";

export default {
  mixins: [FormMixin, ShowMixin],
  name: "General",
  props: {
    resourceId: Number,
    dbRowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    DetailsCard,
    BaseDatepicker,
    BaseIcon,
    BaseSelect,
    BaseCurrency,
  },
  data() {
    return {
      details: null,
      repository: "cash_entry",
      allRepositories: ["cash_entry"],
      cash_references_opt: [],
      DEFERRED_CASH_REFERENCE: 4,
      has_broker: {},
      // treasuries_opt: [],
      mandatoryFields: {},
      isLoading: true,
      generalLabels: null,
      defaultLabels: {
        contacts: {},
      },
      detailFields: {
        scheda_contatto: {
          header: "Scheda Sospeso",
          edit: false,
          repository: "cash_entry",
          fields: [
            {
              label: this.getDictionary("description", "cash_entry"),
              value: "descrizione",
            },
            {
              label: this.getDictionary("cash_time", "cash_entry"),
              type: "datepicker",
              value: "data_registrazione",
            },
            {
              label: "Causale",
              value: "cash_reference_id",
              virtual: (value) => this.getCurrentCashReferences(value),
            },
            {
              label: this.getDictionary("Sospeso"),
              type: "currency",
              value: "importo",
            },
            {
              label: this.getDictionary("Sospeso Incassato"),
              type: "currency",
              virtual: (item) => {
                return item?.recupero;
              },
            },
            {
              label: this.getDictionary("Residuo"),
              type: "currency",
              virtual: (item) => {
                return item?.importo - item?.recupero;
              },
            },
            {
              label: this.getDictionary("Stato Sospeso"),
              value: "",
              virtual: (item) => {
                const importo = parseFloat(item.importo);
                const recupero = item.recupero ? parseFloat(item.recupero) : 0;

                if (!item?.recupero) return "APERTO";

                if (recupero < importo) return "PARZIALMENTE APERTO";

                return "CHIUSO";
              },
            },
          ],
        },
      },
      form: {
        cash_reference_id: null,
        broker_id: null,
        title: null,
        gross: null,
        treasury_id: null,
        cash_entry_id: this.resourceId,
        cash_time: moment().format("YYYY-MM-DD"),
      },
    };
  },
  methods: {
    ...mapGetters("auth", {
      getCashReferences: "cash_references",
      getSalesmen: "salesmen",
      getTreasuries: "allTreasuries",
      getContiPrimaNota: "allContiPrimaNota",
    }),
    fetch() {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.sospeso(this.resourceId)
        .then((response) => {
          const data = response.data;
          this.details = data;
          this.details.importo = parseFloat(data.importo);
          this.details.recupero = data.recupero ? parseFloat(data.recupero) : 0;
          this.form.title = this.details?.descrizione;
          this.form.broker_id = this.details?.id_produttore;
          this.form.treasury_id = this.details?.id_conto_prima_nota;
          this.form.gross = toLocaleCurrency(
            Math.abs(
              (
                parseFloat(this.details.importo) -
                (this.details.recupero ? parseFloat(this.details.recupero) : 0)
              ).toFixed(2)
            )
          );
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    onDetailDateSelect(date) {
      // Typed date validity
      if (moment(date, "DD/MM/YYYY").isBefore(this.minDateSospeso)) {
        let errMsg = `La data non può essere uguale o precedente a ${toLocaleDate(
          this.minDateSospeso
        )}`;
        this.$showSnackbar({
          preset: "info",
          text: `${errMsg}`,
        });
        this.form.cash_time = null;
      }
    },
    onSubmit() {
      this.isLoading = true;
      let form_gross = this.form.gross;
      if (form_gross.toString().indexOf("€") >= 0) {
        form_gross.toString().replace(/[^\d.,]/g, ""); // Convert number to string, remove non-numeric characters except dots and commas
        form_gross = form_gross.replace(".", ""); // Replace commas with dots for correct parsing
        form_gross = form_gross.replace(",", "."); // Replace commas with dots for correct parsing
      }
      form_gross = Math.abs(parseFloat(form_gross).toFixed(2)); // Parse the string to a floating-point number
      const diff = Math.abs(this.details.importo - this.details.recupero).toFixed(2);

      console.log(form_gross, diff, this.details.importo, this.details.recupero)
      if (form_gross <= diff) {
        const payload = {
          id_causale_prima_nota: this.form.cash_reference_id,
          descrizione: this.form.title,
          importo: form_gross,
          id_conto_prima_nota: this.form.treasury_id,
          riferimento_interno: this.resourceId,
          data_registrazione: toLocaleDate(this.form.cash_time, "YYYY-MM-DD"),
        };
        if (this.form.broker_id) payload["id_produttore"] = this.form.broker_id;
        const Repo = RepositoryFactory.get(this.repository);
        Repo.aggiungiRecupero(this.resourceId, payload)
          .then(() => {
            this.fetch().then(() => {
              this.$emit("fetch");
              this.isLoading = false;
              this.$showSnackbar({
                preset: "success",
                text: "Sospeso Recuperato con successo",
              });
            });
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({
              preset: "error",
              text: `${errMsg}`,
            });
            this.isLoading = false;
          });
      } else {
        this.$showSnackbar({
          preset: "success",
          text: `L'importo inserito non deve superare il residuo disponibile`,
        });
        this.isLoading = false;
      }
    },
    onCashReferenceInput(value) {
      if (value) {
        const found = this.cash_references_opt.find(
          (cash_reference) => cash_reference.value === value
        );
        if (found) {
          this.has_broker =
            found.has_broker.value === "Y" ? { required: true } : {};

          const ENTRY_IN_RULE = {
            required: true,
            compare_number: { otherValue: "0", operator: ">" },
          };
          const ENTRY_OUT_RULE = {
            required: true,
            compare_number: { otherValue: "0", operator: "<" },
          };
          this.gross_rule =
            found.entry_type.value === 0 ? ENTRY_IN_RULE : ENTRY_OUT_RULE;
        }
      } else {
        this.gross_rule = { required: true };
      }
    },
    getCurrentCashReferences(value) {
      const cashReferences = this.getCashReferences();
      const found = cashReferences.find(
        (cash_reference) => cash_reference.value == value.id_causale_prima_nota
      );

      return `${found?.text} - ${found?.description}`;
    },
  },
  computed: {
    minDateSospeso() {
      return moment(this.details.data_registrazione).format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.isLoading = true;
    this.cash_references_opt = [];
    let cashReferences = this.getCashReferences();

    cashReferences.sort((a, b) => a.text - b.text);
    cashReferences.forEach((el) => {
      this.cash_references_opt.push({
        text: `${el.text} - ${el.description}`,
        has_broker: el.has_broker,
        entry_type: el.entry_type,
        reference_type: el.reference_type,
        value: el.value,
      });
    });
    this.cash_references_opt = this.cash_references_opt.filter(
      (cash_reference) =>
        cash_reference.reference_type == this.DEFERRED_CASH_REFERENCE
    );
    // this.treasuries_opt = this.getTreasuries().filter(
    //   (treasury) => treasury?.sector?.code == "UPN"
    // );
    this.fetch();
  },
};
</script>
