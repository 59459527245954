<template>
  <b-modal
    :id="id"
    size="lg"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
    @ok="close"
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="archive-fill"></b-icon>
        <span>Chiusura Massiva</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="
          cancel();
          close();
        "
      >
        &times;
      </button>
    </template>
    <div class="mt-2">
      <validation-observer ref="observer1" v-slot="{ handleSubmit, invalid }">
        <b-form @submit.prevent="">
          <b-card class="filter">
            <b-row>
              <div class="col-md-6">
                <base-datepicker
                  vid="book_date"
                  name="data_registrazione"
                  label="Data Registrazione"
                  :min="inputDate"
                  v-model="form.cash_time"
                  :rules="{ required: true }"
                />
              </div>
              <div class="col-md-6">
                <base-select
                  name="id_conto_prima_nota"
                  vid="treasury_id"
                  label="Conto"
                  v-model="form.treasury_id"
                  :options="treasuries_opt"
                  :rules="{ required: true }"
                />
              </div>
            </b-row>
            <b-row>
              <div class="col-md-6">
                <base-select
                  vid="cash_reference_id"
                  name="id_causale_prima_nota"
                  label="Causale"
                  v-model="form.cash_reference_id"
                  :options="cash_references_opt"
                  :taggable="false"
                  :multiple="false"
                  :closeOnSelect="true"
                  :rules="{ required: true }"
                  :preselectFirst="true"
                />
              </div>
              <div class="col-md-6">
                <base-currency
                  name="importo"
                  vid="total_mass_deferred"
                  label="Importo Totale"
                  v-model="inputVal"
                  :options="{
                    currency: 'EUR',
                    locale: 'it-IT',
                    precision: 2,
                  }"
                  readonly
                />
              </div>
            </b-row>
          </b-card>
          <b-button
            @click="handleSubmit(save)"
            type="button"
            :disabled="invalid"
            variant="outline-lisaweb"
            size="sm"
            class="mt-2 mb-4 float-right"
          >
            Salva
          </b-button>
        </b-form>
      </validation-observer>
    </div>
  </b-modal>
</template>
<script>
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseSelect from "@/components/form/BaseSelect";
import BaseCurrency from "@/components/form/BaseCurrency";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      treasuries_opt: [],
      cash_references_opt: [],
      DEFERRED_CASH_REFERENCE: 4,
      form: {
        cash_reference_id: null,
        treasury_id: null,
        cash_time: moment().format("YYYY-MM-DD"),
      },
    };
  },
  props: {
    id: {
      type: String,
      default: "massReducedModal",
    },
    grandtotaldeferred: {
      type: Number,
      default: 0,
    },
    minMassReducedDate: {
      type: String,
      default: "",
    },
  },
  components: {
    BaseDatepicker,
    BaseSelect,
    BaseCurrency,
  },
  methods: {
    save() {
      this.$emit("save", JSON.parse(JSON.stringify(this.form)));
      this.close();
    },
    close() {
      this.form.treasury_id = null;
      this.form.cash_time = moment().format("YYYY-MM-DD");
      this.form.cash_reference_id = null;
      this.$bvModal.hide(this.id);
    },
    ...mapGetters("auth", {
      getCashReferences: "cash_references",
      getContiPrimaNota: "allContiPrimaNota",
    }),
  },
  computed: {
    inputVal: {
      get() {
        return this.grandtotaldeferred;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    inputDate: {
      get() {
        return this.minMassReducedDate;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  mounted() {
    this.treasuries_opt = this.getContiPrimaNota().map((el) => {
      return {
        value: el.value,
        code: el.code,
        text: el.text,
      };
    });
    this.cash_references_opt = [];
    let cashReferences = this.getCashReferences();
    cashReferences.sort((a, b) => a.text - b.text);
    cashReferences.forEach((el) => {
      this.cash_references_opt.push({
        text: `${el.text} - ${el.description}`,
        reference_type: el.reference_type,
        has_broker: el.has_broker,
        entry_type: el.entry_type,
        value: el.value,
        treasury_id: el.treasury_id,
      });
    });
    this.cash_references_opt = this.cash_references_opt.filter(
      (cash_reference) =>
        cash_reference.reference_type == this.DEFERRED_CASH_REFERENCE
    );
    this.form.treasury_id = this.cash_references_opt?.[0]?.treasury_id;
  },
};
</script>

<style lang="scss" scoped>
:deep(.table-responsive, .b-table-sticky-header) {
  overflow-y: visible;
}
</style>
